import { create } from '@storybook/theming';
import tagBrandbookSvg from './TagBrandbookLogo';

export const BrandTheme = create({
  base: 'dark',
  brandImage: null,
  brandTitle: tagBrandbookSvg,
  // Insert logo as img html tag:
  // brandTitle: 'My custom storybook',
  // brandImage: '/apple-touch-icon.png',
  brandUrl: 'https://brand.tag.tn.it',
  brandTarget: 'blank',
});
